<template>
  <div class="card card-default">
    <div class="card-header">
      <h3 class="card-title">{{ widget_meta.title }}</h3>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="function_category bg-info" v-show="items.length > 0">功能選單</div>
        <div v-for="item in items" :key="item.id" :class="[
          'form-group',
          'col-' + widget_meta.widget.properties.col_width.mobile,
          'col-sm-' + widget_meta.widget.properties.col_width.pad,
          'col-md-' + widget_meta.widget.properties.col_width.pad,
          'col-lg-' + widget_meta.widget.properties.col_width.desktop
        ]">
          <router-link v-bind:to="item.path">
            <div class="info-box">
              <span class="info-box-icon bg-info"><i v-bind:class="item.icon"></i></span>
              <div class="info-box-content">
                <span class="info-box-text">{{ item.title }}</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div v-for="item in two_level_items" :key="item.id">
        <div class="row">
          <div class="function_category bg-info">{{ item.title }}</div>
          <div v-for="subitem in item.subitems" :key="subitem.id" :class="[
            'form-group',
            'col-' + widget_meta.widget.properties.col_width.mobile,
            'col-sm-' + widget_meta.widget.properties.col_width.pad,
            'col-md-' + widget_meta.widget.properties.col_width.pad,
            'col-lg-' + widget_meta.widget.properties.col_width.desktop
          ]">
            <router-link v-bind:to="subitem.path">
              <div class="info-box">
                <span class="info-box-icon bg-info"><i v-bind:class="subitem.icon"></i></span>
                <div class="info-box-content">
                  <span class="info-box-text">{{ subitem.title }}</span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  props: ['widget'],

  data() {
    // 取得元件代碼
    let code = this.widget.widget;
    // 取得元件資訊
    let current_widget_meta = this.$microlink.widgets[code];

    let api_url = this.$microlink.base_api_url + current_widget_meta.widget.properties.events.source;
    let response = this.$microlink.api_get(api_url);

    // 先抓出只有第一層的功能
    let items = [];
    let two_level_items = [];
    for (let key in response) {
      if (response[key].path == this.$route.path) {
        continue;
      }

      if (response[key].path != undefined && response[key].path != '') {
        items.push(response[key]);
      }
      else if (response[key].subitems != undefined && response[key].subitems.length > 0) {
        two_level_items.push(response[key]);
      }
    }

    return {
      // 元件資訊
      widget_meta: current_widget_meta,
      // 僅一層的功能
      items: items,
      // 有兩層的功能
      two_level_items: two_level_items
    };
  },
  mounted() {
    this.$forceUpdate();
  }
};
</script>

<style scoped>
.card {
  /** 透明背景 */
  background-color: transparent;
  box-shadow: none;
}

.card-header {
  /** 透明背景 */
  background-color: transparent;
}

div.function_category {
  width: 100%;
  margin: 30px 0px 20px 0px;
  padding: 5px 5px;
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
}
</style>